import { useAppDispatch, useAppSelector } from "../hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    storeVonCustomerFrom,
    storeVonCustomerTo,
    TransferCollaborationsModel,
    VonCustomer
} from "../Reducers/transferCollaborationsReducer";
import { modalTypes } from "../Constants/modalTypes";
import { onToggleModal } from "../Reducers/modalReducer";
import { CreateTransferCollaborationsModal } from "./CreateTransferCollaborationsModal";
import { useNavigate } from "react-router-dom";
import { routes } from "../Constants/routes";
import {useTransferCollaborationsActionCreator} from "../Actions/TransferCollaborationsActionCreator";
import LoadingIcon from "./Icons/LoadingIcon";

export default function TransferCollaborationsList() {
    const activeModal = useAppSelector((state) => state.modal.modal);
    const dispatch = useAppDispatch();
    const transferCollaborationsList = useAppSelector((state) => state.transferCollaborations.transferCollaborationsList);
    const navigate = useNavigate();
    const transferCollaborationsActions = useTransferCollaborationsActionCreator();

    useEffect(() => {
        transferCollaborationsActions.getTransferCollaborationsList();
    }, []);
    
    function createTransferCollaborations() {
        dispatch(onToggleModal(modalTypes.createTransferCollaborationsModal))
    }
    
    function onNavigate(id: string) {
        navigate(routes.transferCollaborationsDetails(id));
    }

    function checkStatus(status: number) {
        if (status == 10 || status == 20)
            return <LoadingIcon></LoadingIcon>;
        else if (status == 15)
            return <i className="vismaicon vismaicon-dynamic vismaicon-edit"></i>;
        else if (status == 200)
            return <i className="vismaicon vismaicon-filled vismaicon-success"></i>;
        else
            return <i className="vismaicon vismaicon-filled vismaicon-error"></i>;
    }
    
    return <>
        {activeModal === modalTypes.createTransferCollaborationsModal && <CreateTransferCollaborationsModal/>}
        <div className="panel">
            <div className="container-padding">
                <div className="d-flex justify-content-end">
                    <button onClick={createTransferCollaborations} className="btn btn-primary">Ny överlåtelse av samarbeten</button>
                </div>
                <div className="table-responsive">
                    <table className="table table-stripe table-regular">
                        <thead>
                        <tr>
                            <th style={{width: "5rem"}}>Detaljer</th>
                            <th>Överlåtelse från</th>
                            <th>Orgnummer</th>
                            <th>Överlåtelse till</th>
                            <th>Orgnummer</th>
                        </tr>
                        </thead>
                        <tbody>
                        {transferCollaborationsList.length === 0 ? (
                            <tr>
                                <td></td>
                                <td>
                                    <p>Ingen data finns tillgänglig</p>
                                </td>
                            </tr>
                        ) : (
                            transferCollaborationsList.map((item: TransferCollaborationsModel, index: number) => {
                                return <tr key={index}>
                                    <td className="text-center">
                                        <span className="cursorpointer"
                                        onClick={() => onNavigate(item.id)}>
                                            {checkStatus(item.status)}
                                        </span>
                                    </td>
                                    <td>{item.customerNameFrom}</td>
                                    <td>{item.orgNumberFrom}</td>
                                    <td>{item.customerNameTo}</td>
                                    <td>{item.orgNumberTo}</td>
                                </tr>
                            })
                        )
                        }
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
}