import React, {useState} from "react";
import Modal from "./Helpers/Modal";
import {useAppSelector} from "../hooks";
import {useTransferCollaborationsActionCreator} from "../Actions/TransferCollaborationsActionCreator";
import {storeVonCustomerTo, VonCustomer} from "../Reducers/transferCollaborationsReducer";
import {useHttp} from "../../Utils/httpUtils";

export function CreateTransferCollaborationsModal() {

    const transferCollaborationsActions = useTransferCollaborationsActionCreator();
    const {request} = useHttp();

    const [customerNumberFrom, setCustomerNumberFrom] = React.useState("");
    const [customerNumberTo, setCustomerNumberTo] = React.useState("");

    const [vonCustomerFrom, setVonCustomerFrom] = React.useState<VonCustomer>();
    const [vonCustomerTo, setVonCustomerTo] = React.useState<VonCustomer>();
    
    const searchVonCustomer = (customerNumber: string, callback: (vonCustomer:VonCustomer) => void) => {
        request(
            `/api/internal/searchVonCustomer/${customerNumber}`,
            null,
            (data: VonCustomer) => callback(data)
        );
    }

    const createTransferCollaborations = () => {
        transferCollaborationsActions.createTransferCollaborations(customerNumberFrom, customerNumberTo);
    }

    const enabledButton = (): boolean => {
        return !!vonCustomerFrom && vonCustomerFrom.customerNumber == customerNumberFrom
            && !!vonCustomerTo && vonCustomerTo.customerNumber == customerNumberTo;
    }

    return <Modal title="Överlåtelse av samarbeten" mainButtonDisabled={!enabledButton()} mainButtonTitle={"Flytta"}
                  onSave={createTransferCollaborations}
                  closeAfterSave={true}>

        <div className="form-group">
            <label>Kundnummer samarbeten ska flyttas från</label>
            <input className="form-control" type="text" placeholder="Kundnummer" value={customerNumberFrom}
                   onBlur={() => customerNumberFrom && searchVonCustomer(customerNumberFrom, setVonCustomerFrom)}
                   onChange={(e) => setCustomerNumberFrom(e.target.value)}/>
        </div>
        {vonCustomerFrom && vonCustomerFrom.name && <div className="alert alert-info">
            <h3>{vonCustomerFrom.name}</h3>
            <p>Kundnummer: {vonCustomerFrom.customerNumber}</p>
            <p>Organisationsnummer: {vonCustomerFrom.organizationNumber}</p>
        </div>
        }
        <div className="form-group">
            <label>Kundnummer samarbeten ska flyttas till</label>
            <input className="form-control" type="text" placeholder="Kundnummer" value={customerNumberTo}
                   onBlur={() => customerNumberTo && searchVonCustomer(customerNumberTo, setVonCustomerTo)}
                   onChange={(e) => setCustomerNumberTo(e.target.value)}/>
        </div>
        {vonCustomerTo && vonCustomerTo.name && <div className="alert alert-info">
            <h3>{vonCustomerTo.name}</h3>
            <p>Kundnummer: {vonCustomerTo.customerNumber}</p>
            <p>Organisationsnummer: {vonCustomerTo.organizationNumber}</p>
        </div>
        }
    </Modal>
}