import { InternalMigration } from "../Reducers/internalMigrationReducer";
import { useHelpers } from "../../Utils/helpers";
import { MigrationStatus } from "./Enums/CommonEnums";
import React from "react";
import { RenderNoData } from "./RenderNoData";

export function MigrationStatusHistory(props: { migration: InternalMigration }) {

    const helpers = useHelpers();
    var history = props.migration.migrationStatusHistory;

    if(!history)
        return null;

    if (history.length === 0)
        return <RenderNoData />

    return <div>
        <div>
            <table className="table table-stripe">
                <thead>
                <tr>
                    <th>Status</th>
                    <th>Ändrat</th>
                </tr>
                </thead>
                <tbody>
                {history.map((item, index: number) =>
                    <tr key={index}>
                        <td>{MigrationStatus[item.status]}</td>
                        <td>{helpers.formatDateAndTime(item.timeOfChange, true)}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </div>;

}