import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../Constants/routes";
import { useAppDispatch, useAppSelector, useAuth } from "../hooks";
import { onToggleModal } from "../Reducers/modalReducer";
import EditUsersModal from "./EditUsersModal";
import { hasRole } from "../../Utils/Users";
import { UserRole } from "../Reducers/userReducer";
import { getAutorizationLink } from "../VismaConnect";
import { useHelpers } from "../../Utils/helpers";

export default function TopNavigation() {

    const dark_mode = "dark-mode";
    const light_theme = "light-theme";
    const dark_theme = "dark-theme";

    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const [adminMenuOpen, setAdminMenuOpen] = useState(false);
    const { user, logout } = useAuth();
    const [responsiveUserMenuOpen, setResponsiveUserMenuOpen] = useState(false);
    const ref = useRef(null);
    const dispatch = useAppDispatch();
    const activeModal = useAppSelector((state) => state.modal.modal);
    const currentUser = useAppSelector((state) => state.users.currentUser);
    const aoCustomers = useAppSelector(state => state.users.aoCustomers);
    const [migrationsToHandle, setToHandle] = useState(0);
    const helper = useHelpers();

    const darkModeRef = useRef();
    const [currentTheme, setCurrentTheme] = useState(light_theme);

    useEffect(() => {
        const darkMode = localStorage.getItem(dark_mode);

        if (!!darkMode && darkMode !== light_theme) {
            setCurrentTheme(dark_theme)
        }
    }, []);

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    useEffect(() => {
        var count = 0;
        aoCustomers.forEach((customer:any) => {
            count += customer.aoMigrationCount.new.length
            count += customer.aoMigrationCount.collaboration
            count += customer.aoMigrationCount.error
        });
        setToHandle(count)
    }, [aoCustomers.length > 0])

    function handleClick(e: any) {
        const current = ref.current as Node | null
        if (current && !current.contains(e.target)) {
            setUserMenuOpen(false);
        }
    }

    const toggleDarkmode = () => {
        const newTheme = currentTheme === light_theme ? dark_theme : light_theme;

        localStorage.setItem(dark_mode, newTheme);

        const html = document.querySelector("html") as HTMLElement;

        const classList = html.classList;
        classList.replace(currentTheme, newTheme)

        setCurrentTheme(newTheme);
    };


    return (
        <header className="navbar navbar-default">
            <div className="navbar-header">
                <div className="navbar-brand">
                    <Link to={routes.home}>Visma Spcs Flytthjälp</Link>
                </div>
            </div>
            {user && <> <span className="phone-tablet-view">
                <div className="d-flex justify-content-end"
                    onClick={() => setResponsiveUserMenuOpen(!responsiveUserMenuOpen)}>
                    <a href="#" className="menu-icon"><i
                        className="vismaicon vismaicon-sm vismaicon-dynamic vismaicon-tools"></i></a>
                </div>
                <nav className={`collapse navbar-collapse ${responsiveUserMenuOpen ? "responsive" : ""}`}
                    aria-hidden="false">
                    <ul className="nav">
                        {/*<li className="dropdown user-dropdown">*/}
                        {/*    <Link className="nav-list-item" to={routes.myPage}*/}
                        {/*          onClick={() => setResponsiveUserMenuOpen(false)}>Min sida</Link>*/}
                        {/*</li>*/}
                        <li>
                            <Link className="nav-list-item" to={routes.summarizedList}
                                onClick={() => setResponsiveUserMenuOpen(false)}>Status</Link>
                        </li>
                        {hasRole(currentUser, UserRole.ReadWrite) && <li>
                            <Link className="nav-list-item" to={routes.internalList}>Intern lista</Link>
                        </li>}
                        <li className="dropdown user-dropdown">
                            <Link className="nav-list-item" onClick={() => logout()} to="/">Logga ut</Link>
                        </li>
                    </ul>
                </nav>
            </span>
                <nav className="collapse navbar-collapse">
                    <ul className="nav">
                        <li>
                            <Link className="nav-list-item" to={routes.summarizedList}>Status
                                {migrationsToHandle > 0 && <span className="badge">{migrationsToHandle}</span>}
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Link className="nav-list-item" to={routes.startGuide}>Guide</Link>*/}
                        {/*</li>*/}
                        {hasRole(currentUser, UserRole.ReadWrite) && <li>
                            <Link className="nav-list-item" to={routes.internalList}>Intern lista</Link>
                        </li>}
                        {hasRole(currentUser, UserRole.ReadWrite) && <li>
                            <Link className="nav-list-item" to={routes.transferCollaborations}>Överlåtelse samarbeten</Link>
                        </li>}
                    </ul>
                    <ul className="nav navbar-nav nav-tabs navbar-right first-level context-selector">
                        {hasRole(currentUser, UserRole.Admin) && <>
                            <li className={`dropdown icon admin2-dropdown ${adminMenuOpen ? "open" : ""}`}>
                                <a href="#" className="dropdown-toggle button-context" data-toggle="dropdown"
                                    aria-expanded={adminMenuOpen} onClick={() => setAdminMenuOpen(!adminMenuOpen)}>
                                    <span className="vismaicon vismaicon-menu vismaicon-settings vismaicon-dynamic "></span>
                                </a>
                                <ul className="dropdown-menu" aria-expanded={adminMenuOpen}
                                    aria-hidden={!adminMenuOpen}>
                                    <li>
                                        <Link onClick={() => setAdminMenuOpen(!adminMenuOpen)} to={routes.adminPageCreateTestMigration}>Skapa testföretag</Link>
                                    </li>
                                    <li>
                                        <Link onClick={() => setAdminMenuOpen(!adminMenuOpen)} to={routes.adminReportPage}>Rapport över slutförda överflytter</Link>
                                    </li>
                                    <li>
                                        <Link onClick={() => setAdminMenuOpen(!adminMenuOpen)} to={routes.exceptionLogs}>Error log</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="icon">
                                <a href="#" type="button" onClick={() => dispatch(onToggleModal("editUsersModal"))}> <span className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-user-settings"></span></a>
                            </li>
                        </>}
                        <li ref={ref} className={`dropdown user-dropdown ${userMenuOpen ? "open" : ""}`}>
                            <a href="#" className="dropdown-toggle button-context" data-toggle="dropdown"
                                aria-expanded={userMenuOpen} onClick={() => setUserMenuOpen(!userMenuOpen)}>
                                {user?.name}
                                <span className="vismaicon vismaicon-menu vismaicon-user"></span>
                                <small className="selectedContext">{helper.authorizedCompanyName()}</small>
                                <span className="caret"></span>
                            </a>
                            <ul className="dropdown-menu company-selection" aria-expanded={userMenuOpen}
                                aria-hidden={!userMenuOpen}>
                                <li>
                                    <label className="switch switch-label light-dark-toggle" style={{width: 400}}>
                                        Tema
                                        <input id="switch-darkmode" type="checkbox" name="switch-darkmode"
                                               checked={currentTheme === dark_theme}
                                               onChange={() => toggleDarkmode()}/>
                                        <span className="togglemark"></span>
                                    </label>
                                </li>
                                <li>
                                    <Link onClick={helper.changeAuthCompany} to="/"><i
                                        className="vismaicon vismaicon-sm vismaicon-dynamic vismaicon-organisation"></i>Byt företag</Link>
                                </li>
                                <li>
                                    <Link onClick={() => logout()} to="/"><i
                                        className="vismaicon vismaicon-sm vismaicon-dynamic vismaicon-logout"></i>
                                        Logga ut
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                {activeModal === "editUsersModal" && <EditUsersModal />}
            </>}
        </header>
    );
}