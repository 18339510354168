
export const routes = {
    home: "",
    login: "/login",
    myPage: "/my-page",
    csvImport: "/file-import",
    csvIntro: "/file-import-intro",
    apiCall: "/call-api",
    about: "/about",
    settings: "/settings",
    userSettings: "/user-settings",
    summarizedList: "/status",
    aoStatusList: (id:string, status:string) => `/ao-status-list/${id}/${status}`,
    logout: "",
    startGuide: () => `/guide`,
    startGuideSMB: (sourceType: string) => `/smb-guide/${sourceType}`,
    startGuideFortnox: (migrationId:string) => `/fortnox-guide/${migrationId}`,
    fortnoxSMBIntro: "/fortnox-smb-intro",
    fortnoxAOIntro: "/fortnox-ao-intro",
    internalList: "/internal-list",
    internalMigrationDetails: (id:string) => `/migration-details/${id}`,
    transferCollaborations: "/transfer-collaborations",
    transferCollaborationsDetails: (id: string) => `/transfer-collaborations-details/${id}`,
    migrationMain: "/migration-main",
    adminPage: "/admin",
    adminPageCreateTestMigration: "admin/create-test-migration",
    adminReportPage: "admin/reports",
    exceptionLogs: "admin/exceptions",
    additionalInfo: (id:string) => `/additionalInfo/${id}`,
    smbMigrationDetails: (sourceType: string) => `/smb-migration-details/${sourceType}`,
    importFilesConfirm: "/file-import-confirmation"
}