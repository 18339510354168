import {useHttp} from "../../Utils/httpUtils";
import {useAppDispatch, useAppSelector, useAuth} from "../hooks";
import {
    storeTransferCollaborations,
    storeTransferCollaborationsDetails,
    storeVonCustomerTo,
    storeVonCustomerFrom,
    VonCustomer,
    TransferCollaborationsModel,
    optimisticallySetTransferCollaborationStatus,
    TransferCollaborationsStatus,
    TransferCollaborationsDetails
} from "../Reducers/transferCollaborationsReducer";

export const useTransferCollaborationsActionCreator = () => {
    const dispatch = useAppDispatch();
    const {request, requestWithBody} = useHttp();


    const getTransferCollaborationsList = () => {
        request(
            '/api/internal/getTransferCollaborationsList',
            null,
            (data: TransferCollaborationsModel[]) => dispatch(storeTransferCollaborations(data))
        );
    }
    
    const getTransferCollaborationsDetails = (id: string | undefined) => {
        request(
            `/api/internal/getTransferCollaborationsDetails/${id}`,
            null,
            (data: TransferCollaborationsModel) => dispatch(storeTransferCollaborationsDetails(data)));
    }
    
    const searchVonCustomerTo = (customerNumber: string) => {
        request(
            `/api/internal/searchVonCustomer/${customerNumber}`,
            null,
            (data: VonCustomer) => dispatch(storeVonCustomerTo(data))
        );
    }
    
    const searchVonCustomerFrom = (customerNumber: string) => {
        request(
            `/api/internal/searchVonCustomer/${customerNumber}`,
            null,
            (data: VonCustomer) => dispatch(storeVonCustomerFrom(data))
        );
    }
    
    const createTransferCollaborations = (customerNumberFrom: string, customerNumberTo: string) => {
        
        const httpRequest = {
            method: 'POST',
            body: JSON.stringify({customerNumberFrom, customerNumberTo})
        }
        
        request(`/api/internal/transferCollaborations`, httpRequest);
    }
    
    const continueTransferCollaborations = (id: string) => {
        request(
            `/api/internal/continueTransferCollaborations/${id}`,
            {method: 'POST'},
            () => dispatch(optimisticallySetTransferCollaborationStatus(TransferCollaborationsStatus.SaveCollaborations))
        )
    }

    return {
        createTransferCollaborations,
        searchVonCustomerTo,
        searchVonCustomerFrom,
        getTransferCollaborationsList,
        getTransferCollaborationsDetails,
        continueTransferCollaborations
    }
}

